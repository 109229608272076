import React from 'react';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import DescriptionIcon from '@material-ui/icons/Description';
import { Grid, Tooltip, Typography } from '@material-ui/core';

import { useStyles } from './styles';

const STRIPE_INVOICE_URL = "https://dashboard.stripe.com/invoices";

const Payment = (props) => {
    const { type, payment } = props;
    const netAmount = payment.net;
    const classes = useStyles( { type } );
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClose = () => setAnchorEl(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const invoiceLink = (event, invoiceID) => {
        event.preventDefault();
        window.open(`${STRIPE_INVOICE_URL}/${invoiceID}`, '_blank');
    }

    const showInvoice = (event, invoiceUrl) => {
        event.preventDefault();
        if (invoiceUrl)
            window.open(invoiceUrl, '_blank');
    }

    const hasInvoice = payment.invoice_pdf !== undefined;

    return (
        <>
            <Grid container spacing={1} justifyContent='center' alignItems='center' className={classes.payment} onClick={handleClick}>
                <Grid item xs={2}>
                    <Grid container spacing={0} justifyContent='center' alignItems='center' direction='row' className={classes.amount}>
                        <Grid item xs={12} align='center'>
                            <Typography variant="body1">{netAmount}€</Typography>
                        </Grid>
                        <Grid item xs={12} align='center'>
                            <Typography variant="caption" color='textSecondary' ><i>${payment.amount}</i></Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <Grid container spacing={0} justifyContent='center' alignItems='center' direction='row' >
                        <Grid item xs={12}>
                            <Grid container spacing={1} justifyContent='center' alignItems='center'>
                                <Grid item xs={6}>
                                    <Typography variant="caption"><strong>{ payment.invoice_name }</strong></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="caption">{ payment.customer_email }</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1} justifyContent='center' alignItems='center'>
                                <Grid item >
                                    <Tooltip title={hasInvoice ? 'View invoice' : 'No invoice available'}>
                                        <Chip
                                            size='small'
                                            variant="outlined"
                                            icon={<DescriptionIcon />}
                                            color={hasInvoice ? 'primary' : 'secondary'}
                                            label={hasInvoice ? 'Invoice' : 'No invoice'}
                                            onClick={(e) => showInvoice(e, payment.invoice_pdf)}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item >
                                    <Tooltip title="Created at">
                                        <Chip variant="outlined" color="primary" size="small" label={ new Date(payment.created_at).toLocaleString() } />
                                    </Tooltip>
                                </Grid>
                                {payment?.arrival_date && (
                                    <Grid item >
                                        <Tooltip title="Arrival estimated">
                                            <Chip variant="outlined" color="primary" size="small" label={ new Date(payment.arrival_date).toLocaleString() } />
                                        </Tooltip>
                                    </Grid>
                                )}
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {Object.keys(payment).map((key, index) => 
                    (key === 'invoice')
                        ? <Typography key={index} className={classes.typographyPopover} onClick={event => invoiceLink(event, payment[key])} ><u>{key}: {payment[key]}</u></Typography>
                        : <Typography key={index} className={classes.typographyPopover}>{key}: {payment[key]}</Typography>
                )}
            </Popover>
        </>

    );
}

const PaymentsRecapPanel = (props) => {
    const { type, list, amount } = props;

    const NoPayments = () => <Typography variant="body1"><em>No payments</em></Typography>

    const sortedList = Object.keys(list || {}).map(key => { return { key, ...list[key] } }).sort((a, b) => { return b.invoice_name.localeCompare(a.invoice_name) });
    return (
        <Grid container spacing={1} justifyContent='center' alignItems='center'>
            <Grid item xs={12}>
                <Typography variant="h5">{`Payout in ${type} (${amount}€)`}</Typography>
            </Grid>
            <Grid item xs={12}>
                { sortedList && sortedList.map(payment => 
                    <div key={payment.key}  > 
                        <Payment payment={payment} type={type}/>
                    </div>
                )}
                { sortedList && sortedList.length === 0 && <NoPayments /> }
            </Grid>
        </Grid>
    );
}


export default PaymentsRecapPanel;